import React, { useState, createContext, useEffect } from "react"
import { DynamoDBClient } from "@aws-sdk/client-dynamodb"
import { TranslateClient } from "@aws-sdk/client-translate"
import { S3Client } from "@aws-sdk/client-s3"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"

const GlobalContext = createContext(null)

const GlobalProvider = props => {
  const isBrowser = () => typeof window !== "undefined"
  const providerID = isBrowser() ? window.location.search.slice(5) : null
  const [loading, setLoading] = useState(true)
  const [language, setLanguage] = useState("en")
  const [organization, setOrganization] = useState(
    isBrowser() && window.localStorage.getItem("organization")
      ? window.localStorage["organization"]
      : null
  )
  const [primaryColor, setPrimaryColor] = useState("#C1272D")
  const [secondaryColor, setSecondaryColor] = useState("#F50057")
  const [providerForm, setProviderForm] = useState({})
  const [filteredProviderForm, setFilteredProviderForm] = useState({})
  const [customFormEnabled, setCustomFormEnabled] = useState(false)
  const [translationEnabled, setTranslationEnabled] = useState(false)
  const [db, setDb] = useState(null)
  const [translator, setTranslator] = useState(null)
  const [s3, setS3] = useState(null)
  const [userID, setUserID] = useState(null)
  const [newUser, setNewUser] = useState(true)
  const [userData, setUserData] = useState(null)
  const [answers, setAnswers] = useState({})
  const [currentLanguage, setCurrentLanguage] = useState("en")
  const [alertMessage, setAlertMessage] = useState("")
  const [error, setError] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0)
  const [submissionLoading, setSubmissionLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Create db and translator instances on the client-side
      setDb(
        new DynamoDBClient({
          region: "us-east-1",
          credentials: fromCognitoIdentityPool({
            clientConfig: { region: "us-east-1" },
            identityPoolId: "us-east-1:1a9a8ad2-cf14-4779-878a-fb869c818fea",
            //customRoleArn: "arn:aws:iam::783364685273:role/service-role/Cognito_AdminPortalUnauth",
          }),
        })
      )
      setTranslator(
        new TranslateClient({
          region: "us-east-1",
          credentials: fromCognitoIdentityPool({
            clientConfig: { region: "us-east-1" },
            identityPoolId: "us-east-1:1a9a8ad2-cf14-4779-878a-fb869c818fea",
            //customRoleArn: "arn:aws:iam::783364685273:role/service-role/Cognito_AdminPortalUnauth",
          }),
        })
      )
      setS3(
        new S3Client({
          region: "us-east-1",
          credentials: fromCognitoIdentityPool({
            clientConfig: { region: "us-east-1" },
            identityPoolId: "us-east-1:1a9a8ad2-cf14-4779-878a-fb869c818fea",
            //customRoleArn: "arn:aws:iam::783364685273:role/service-role/Cognito_AdminPortalUnauth",
          }),
        })
      )
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        isBrowser,
        loading,
        setLoading,
        language,
        setLanguage,
        organization,
        setOrganization,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        providerID,
        db,
        translator,
        providerForm,
        setProviderForm,
        filteredProviderForm,
        setFilteredProviderForm,
        customFormEnabled,
        setCustomFormEnabled,
        translationEnabled,
        setTranslationEnabled,
        userID,
        setUserID,
        newUser,
        setNewUser,
        userData,
        setUserData,
        answers,
        setAnswers,
        currentLanguage,
        setCurrentLanguage,
        alertMessage,
        setAlertMessage,
        error,
        setError,
        currentIndex,
        setCurrentIndex,
        submissionLoading,
        setSubmissionLoading,
        submitted,
        setSubmitted,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }
